import { Box, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { Router } from "../../../i18n";
import useStyles from "../../../styles/components/Navbar";

type NavbarDefaultType = {
  title: string;
  onClickBack?: () => void;
  withLogo: boolean;
};

const defaultProps = {
  withLogo: false,
  title: "",
};

const NavbarDefault = (props: NavbarDefaultType) => {
  const { title, withLogo, onClickBack } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.navbarWrapper}>
      <Box
        className={clsx(classes.navbarContent, classes.navbarDefaultWrapper)}
      >
        {onClickBack && (
          <ArrowBack
            className={classes.icon}
            onClick={() => {
              onClickBack();
            }}
          />
        )}
        {withLogo && (
          <img
            src={
              process.env.NEXT_PUBLIC_COLOR_LOGO ||
              "https://swaps-bucket.s3.ap-southeast-1.amazonaws.com/Group%2022.png-2022-08-30T06%3A18%3A48.910Z"
            }
            alt="logo"
            width={process.env.WIDTH_LOGO || 50}
            onClick={() => Router.push("/")}
            className={classes.icon}
          />
        )}
        {!withLogo && (
          <Typography className={classes.navbarTitle}>{title}</Typography>
        )}
      </Box>
    </Box>
  );
};

NavbarDefault.defaultProps = defaultProps;

export default NavbarDefault;
