import { Dialog, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TFunction } from "next-i18next";
import React from "react";

interface TypeLoading {
  open: boolean;
  onClose?: (args: any) => void;
  readonly t: TFunction;
}

const Loading = (props: TypeLoading) => {
  const classes = useStyles(props);
  const { open, onClose, t } = props;
  return (
    <>
      <Dialog disableScrollLock={true} open={open} onClose={onClose && onClose}>
        <div className={classes.root}>
          <CircularProgress />
          <Typography className={classes.text}>{t("please-wait")}</Typography>
        </div>
      </Dialog>
    </>
  );
};

export default Loading;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: "center",
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 15,
      paddingTop: 10,
    },
    text: {
      fontWeight: "bold",
      paddingTop: 10,
    },
  })
);
