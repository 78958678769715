import { Box, Button, Typography } from "@material-ui/core";
import { CityDto } from "../libs/city";
import useStyles from "../../styles/search-location";
import React from "react";
import { TFunction } from "next-i18next";
import { LocationOn } from "@material-ui/icons";

type CardCityType = {
  city: CityDto;
  onSelectCity: () => void;
  readonly t: TFunction;
};

export default function CardCity(props: CardCityType) {
  const { city, onSelectCity, t } = props;
  const classes = useStyles(props);

  return (
    <Button onClick={onSelectCity} className={classes.cardCity}>
      <Box display="flex">
        <LocationOn color="primary" className={classes.locationIcon} />
        <Box ml="8px" textAlign="left">
          <Typography className={classes.itemName} variant="body1">
            {city.name}
          </Typography>
          <Typography className={classes.totalHotel} variant="body1">
            {city.total_hotels} {t("Hotels-here")}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        color="primary"
        className={classes.chooseButton}
      >
        {t("choose")}
      </Typography>
    </Button>
  );
}
