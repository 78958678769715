const UserProfileIcon = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.580078"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        fill="#F2F2F2"
      />
      <rect
        x="0.580078"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        stroke="black"
      />
      <path
        d="M21.8416 21.8912C21.4427 20.7749 20.5639 19.7884 19.3414 19.0849C18.1189 18.3813 16.621 18 15.0801 18C13.5391 18 12.0413 18.3813 10.8187 19.0849C9.59624 19.7884 8.71742 20.7749 8.3186 21.8912"
        stroke="#121212"
        stroke-width="0.875"
        stroke-linecap="round"
      />
      <circle
        cx="15.0801"
        cy="11"
        r="3.5"
        stroke="#121212"
        stroke-width="0.875"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default UserProfileIcon;
