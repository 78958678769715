import { TFunction } from "next-i18next";
import NavbarMenu from "./NavbarMenu";
import React from "react";
import NavbarDefault from "./NavbarDefault";

type NavbarProps = {
  readonly t: TFunction;
  onClickAuth?: () => void;
  onClickBack?: () => void;
  onClickOrderRoom?: () => void;
  title: string;
  withMenu: boolean;
  withLogo: boolean;
  isLogin: boolean;
};

const defaultProps = {
  withMenu: false,
  withLogo: false,
  title: "",
  isLogin: false,
};

const Navbar = (props: NavbarProps) => {
  const {
    t,
    withMenu,
    withLogo,
    isLogin,
    title,
    onClickBack,
    onClickAuth,
    onClickOrderRoom,
  } = props;
  return (
    <>
      {(withMenu || onClickAuth) && (
        <NavbarMenu
          t={t}
          onClickAuth={onClickAuth}
          isLogin={isLogin}
          onClickOrderRoom={onClickOrderRoom}
        />
      )}
      {title && (
        <NavbarDefault
          title={title}
          onClickBack={onClickBack}
          withLogo={withLogo}
        />
      )}
    </>
  );
};

Navbar.defaultProps = defaultProps;

export default Navbar;
