import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Dialog,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close, LocationOn, Search } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import "dayjs/locale/id";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import { i18n, Router } from "../../i18n";
import CheckinIcon from "../../public/img/CheckinIcon";
import CheckoutIcon from "../../public/img/CheckoutIcon";
import NotFoundIcon from "../../public/img/NotFoundIcon";
import useStyles from "../../styles/components/SelectCheckinCheckout";
import { CityDto } from "../libs/city";
import nextDayDate from "../utils/nextDayDate";
import CardCity from "./CardCity";

interface DateCheckinType {
  in: Date | null;
  out: Date | null;
}

type Props = {
  readonly t: TFunction;
  hotel?: string;
  listCity?: CityDto[];
  dateCheckin: DateCheckinType;
  handleSearch: (city: CityDto) => void;
  handleSearchCity?: (value: string) => void;
  handleUpdateCheckin: (date) => void;
  handleUpdateCheckout: (date) => void;
};

const SelectCheckinCheckout = (props: Props) => {
  const {
    t,
    hotel,
    listCity,
    dateCheckin,
    handleSearch,
    handleSearchCity,
    handleUpdateCheckin,
    handleUpdateCheckout,
  } = props;
  const classes = useStyles();
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [openSelectCity, setOpenSelectCity] = useState(false);
  const [city, setCity] = useState<CityDto>(Object);
  const language = i18n.language;
  const hotelUsername = Router.query.hotel_username as string;
  const certainHotel = process.env.CERTAIN_HOTEL?.split(",") as string[];

  const handleOpenSelectCity = () => {
    setOpenSelectCity(!openSelectCity);
  };

  useEffect(() => {
    const cityLocal = JSON.parse(localStorage.getItem("city") || "{}");

    if (cityLocal.id) {
      setCity(cityLocal);
    }
  }, []);

  const disableButton = () => {
    if (hotel) {
      if (!dateCheckin) {
        return true;
      }
    }
    if (!hotel) {
      if (!certainHotel?.length && (!dateCheckin || !city.id)) {
        return true;
      }
    }
    if (hotelUsername && !dateCheckin) {
      return true;
    }
  };

  return (
    <Box>
      {!hotel && (
        <Typography variant="h2" className={classes.title}>
          {t("where-to-stay")}
        </Typography>
      )}
      <Box className={classes.selectDataWrapper}>
        {!certainHotel?.length && !hotel && (
          <Box width="100%">
            <Typography className={classes.label}>{t("location")}</Typography>
            <Box
              className={classes.inputWrapper}
              onClick={() => handleOpenSelectCity()}
            >
              <LocationOn className={classes.locationIcon} color="primary" />
              {city.id ? (
                <Typography variant="body1" className={classes.selectedCity}>
                  {city.name}
                </Typography>
              ) : (
                <Typography className={classes.placeholder} variant="body1">
                  {t("find-hotel-location")}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box width="100%">
          <Typography className={classes.label}>Check-In</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={dateCheckin.in}
              onChange={(date) => handleUpdateCheckin(date)}
              animateYearScrolling
              minDate={new Date()}
              open={openCheckIn}
              onClose={() => setOpenCheckIn(false)}
              TextFieldComponent={() => (
                <Box
                  className={classes.inputWrapper}
                  onClick={() => setOpenCheckIn(true)}
                >
                  <CheckinIcon />
                  <Typography className={classes.selectedDate}>
                    {dayjs(dateCheckin.in)
                      .locale(language)
                      .format("D MMM YYYY")}
                  </Typography>
                </Box>
              )}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box width="100%">
          <Typography className={classes.label}>Check-Out</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={dateCheckin.out}
              onChange={(date) => handleUpdateCheckout(date)}
              animateYearScrolling
              open={openCheckOut}
              minDate={nextDayDate(dateCheckin.in)}
              onClose={() => setOpenCheckOut(false)}
              TextFieldComponent={() => (
                <Box
                  className={classes.inputWrapper}
                  onClick={() => setOpenCheckOut(true)}
                >
                  <CheckoutIcon />
                  <Typography className={classes.selectedDate}>
                    {dayjs(dateCheckin.out)
                      .locale(language)
                      .format("D MMM YYYY")}
                  </Typography>
                </Box>
              )}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          className={classes.searchButton}
          disabled={disableButton()}
          onClick={() => {
            handleSearch(city);
            localStorage.setItem("city", JSON.stringify(city));
          }}
        >
          {t("search-room")}
        </Button>
      </Box>
      <Dialog open={openSelectCity} className={classes.dialogListCity}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{t("search-location")}</Typography>
          <Close onClick={handleOpenSelectCity} className={classes.closeIcon} />
        </Box>
        <TextField
          onChange={(event) => {
            handleSearchCity && handleSearchCity(event.target.value);
          }}
          fullWidth
          id="find-hotel-location"
          placeholder={t("find-hotel-location")}
          className={classes.inputCity}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={classes.searchIcon} color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <Box className={classes.listCityWrapper}>
          {listCity?.length ? (
            listCity.map((city, index) => (
              <Box key={city.id}>
                <CardCity
                  city={city}
                  t={t}
                  onSelectCity={() => {
                    setOpenSelectCity(false);
                    setCity(city);
                  }}
                />
                {listCity.length !== index + 1 && (
                  <Divider className={classes.dividerCity} />
                )}
              </Box>
            ))
          ) : (
            <Box textAlign="center">
              <NotFoundIcon />
              <Typography className={classes.locationNotFound} variant="body1">
                {t("location-not-found")}
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default SelectCheckinCheckout;
