import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbarWrapper: {
      position: "fixed",
      top: 0,
      zIndex: 999,
      width: "100%",
      background: "#fafafaf1",
    },
    navbarContent: {
      maxWidth: "80vw",
      margin: "auto",
      padding: "16px 0px",
      display: "flex",
      [theme.breakpoints.down(460)]: {
        maxWidth: "90vw",
      },
    },
    icon: {
      cursor: "pointer",
    },
    menuWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 0,
      gap: 40,
      [theme.breakpoints.down(770)]: {
        flexDirection: "column",
        gap: 20,
        width: "100%",
        alignItems: "center",
      },
    },
    menu: {
      cursor: "pointer",
      listStyle: "none",
      textAlign: "center",
      fontSize: 16,
      [theme.breakpoints.down(769)]: {
        width: "100%",
      },
      fontWeight: 700,
      fontFamily: "'Playfair Display', serif",
      textTransform: "capitalize",
    },
    loginButton: {
      borderRadius: 100,
      padding: "8px 24px",
      fontWeight: 700,
      fontSize: 16,
      [theme.breakpoints.down(769)]: {
        width: "100%",
      },
    },
    navbarMenuWrapper: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    navbarDefaultWrapper: {
      alignItems: "center",
      gap: 16,
    },
    navbarTitle: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "'Playfair Display', serif",
      textTransform: "capitalize",
      [theme.breakpoints.down(450)]: {
        fontSize: 16,
      },
    },
    iconButton: {
      padding: 0,
    },
    mobileIconWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  })
);

export default useStyles;
