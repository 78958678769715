import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 16,
      fontWeight: 700,
    },
    selectDataWrapper: {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      gap: 24,
      [theme.breakpoints.down(993)]: {
        flexDirection: "column",
      },
    },
    locationIcon: {
      height: 33,
      width: 33,
    },
    searchIcon: {
      height: 32,
      width: 32,
    },
    placeholder: {
      color: "#929292",
      marginLeft: 8,
    },
    inputWrapper: {
      cursor: "pointer",
      display: "flex",
      width: "100%",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: 8,
      padding: "7px 16px",
    },
    inputCity: {
      margin: "24px 0px",
    },
    label: {
      marginBottom: 8,
    },
    city: {
      cursor: "pointer",
    },
    locationNotFound: {
      fontWeight: 700,
    },
    selectedDate: {
      marginLeft: 8,
      whiteSpace: "nowrap",
    },
    searchButton: {
      borderRadius: 100,
      padding: "16px 32px",
      fontWeight: 700,
      fontSize: 16,
      [theme.breakpoints.down(993)]: {
        alignSelf: "flex-end",
      },
      [theme.breakpoints.down(460)]: {
        alignSelf: "center",
        width: "100%",
      },
      minWidth: "fit-content",
      height: 47,
    },
    dividerCity: {
      margin: "8px 0px",
    },
    listCityWrapper: {
      maxHeight: 400,
      overflow: "auto",
    },
    closeIcon: {
      cursor: "pointer",
    },
    dialogListCity: {
      "& .MuiDialog-paperWidthSm": {
        width: 420,
        padding: 24,
        borderRadius: 16,
        "@media (max-width:425px)": {
          width: "90vw",
        },
      },
    },
    selectedCity: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  })
);

export default useStyles;
