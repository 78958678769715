import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: "#FFFFFF",
      height: "100%",
      minHeight: "100vh",
      paddingTop: 135,
    },
    headWrapper: {
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 1,
    },
    title: {
      paddingLeft: 20,
      fontWeight: "bold",
    },
    titleDaftarLokasi: {
      backgroundColor: "#e8e8e8",
      fontSize: 12,
      color: "#52575c",
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
    itemWrapper: {
      padding: "10px 20px",
      paddingBottom: 0,
      backgroundColor: "#ffffff",
    },
    itemName: {
      fontWeight: 700,
    },
    chooseButton: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    totalHotel: {
      color: "#5F5F5F",
    },
    itemTotalHotelWrapper: {
      display: "grid",
      justifyContent: "flex-end",
      width: "100%",
    },
    skeletonWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    itemPilihSkeleton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    notFoundWraper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "70vh",
    },
    notFoundText: {
      fontSize: "14px",
      color: "#52575C",
      width: "298px",
      textAlign: "center",
    },
    cardCity: {
      padding: "0px 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    locationIcon: {
      width: 32,
      height: 32,
    },
  })
);

export default useStyles;
