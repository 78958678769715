import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import { Instagram, MailOutline, WhatsApp } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React from "react";
import { Router } from "../../i18n";
import useStyles from "../../styles/components/Footer";
import { HotelDetailDto } from "../libs/hotel";

type FooterProps = {
  readonly t: TFunction;
  onClickAuth?: () => void;
  onClickOrderRoom?: () => void;
  isLogin?: boolean;
  hotel?: HotelDetailDto;
};

const Footer = (props: FooterProps) => {
  const { t, onClickAuth, onClickOrderRoom, isLogin, hotel } = props;
  const classes = useStyles();
  const tenantModule = process.env.TENANT_MODULE?.split(",");
  const tenantInstagram = process.env.TENANT_INSTAGRAM;
  const tenantWhatsapp = process.env.TENANT_WHATSAPP;
  const tenantEmail = process.env.TENANT_EMAIL;

  const onClickMenu = (type) => {
    if (type === "room") {
      if (onClickOrderRoom) {
        onClickOrderRoom();
      }
      return;
    }
    if (type === "food") {
      Router.push("/hotels/foods/search-location");
    }
    if (type === "souvenir") {
      Router.push("/hotels/souvenirs/search-location");
    }
    if (type === "wallet") {
      if (onClickAuth && !isLogin) {
        onClickAuth();
      } else {
        Router.push("/balance?page=wallet");
      }
    }
  };

  return (
    <Box className={classes.footerWrapper}>
      <Box className={classes.footerContent}>
        <Box className={classes.footerTopContent}>
          <Box>
            <Typography variant="body1" className={classes.titleContent}>
              {hotel ? hotel?.name : process.env.TENANT_NAME || "Pesan.io"}
            </Typography>
            <Typography className={classes.hotelAddress}>
              {hotel ? hotel?.address : process.env.TENANT_ADDRESS}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.titleContent}>
              {t("pages")}
            </Typography>
            <ul className={classes.footerPageList}>
              {tenantModule?.map((type, index) => (
                <li
                  className={classes.footerPage}
                  key={index}
                  onClick={() => onClickMenu(type)}
                >
                  {t(type)}
                </li>
              ))}
              <li className={classes.footerPage}>{t("privacy-policy")}</li>
            </ul>
          </Box>
          {(tenantEmail || tenantInstagram || tenantWhatsapp) && (
            <Box>
              <Typography variant="body1" className={classes.titleContent}>
                {t("contact-us")}
              </Typography>
              <Box className={classes.socialMediaWrapper}>
                {tenantWhatsapp && (
                  <IconButton
                    className={classes.socialMediaButton}
                    onClick={() => {
                      window.open(
                        `https://wa.me/${tenantWhatsapp || "6288802870057"}`,
                        "_blank"
                      );
                    }}
                  >
                    <WhatsApp />
                  </IconButton>
                )}
                {tenantInstagram && (
                  <IconButton
                    className={classes.socialMediaButton}
                    onClick={() =>
                      window.open(
                        `https://www.instagram.com/${
                          tenantInstagram || "pesan_io"
                        }/`,
                        "_blank"
                      )
                    }
                  >
                    <Instagram />
                  </IconButton>
                )}
                {tenantEmail && (
                  <IconButton
                    className={classes.socialMediaButton}
                    onClick={() => {
                      window.open(
                        `mailto:${tenantEmail || "r@jawara.io"}`,
                        "_blank"
                      );
                    }}
                  >
                    <MailOutline />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Divider className={classes.divider} />
        <Typography className={classes.copywriting}>
          &copy; 2024 Pesan.io - All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
