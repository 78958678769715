import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      backgroundColor: theme.palette.primary.dark,
      color: "white",
    },
    footerContent: {
      padding: "40px 0px",
      maxWidth: "80vw",
      margin: "auto",
      [theme.breakpoints.down(460)]: {
        maxWidth: "90vw",
      },
    },
    footerTopContent: {
      display: "flex",
      flexWrap: "wrap",
      gap: "48px 160px",
      [theme.breakpoints.down(1025)]: {
        gap: 0,
        justifyContent: "space-between",
      },
      [theme.breakpoints.down(766)]: {
        flexDirection: "column",
        gap: 48,
      },
    },
    titleContent: {
      marginBottom: 8,
      fontFamily: "'Playfair Display', serif",
      fontWeight: 700,
    },
    hotelAddress: {
      maxWidth: 300,
    },
    socialMediaWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
    footerPageList: {
      padding: 0,
      margin: 0,
      display: "grid",
      gap: 8,
    },
    footerPage: {
      cursor: "pointer",
      listStyle: "none",
      fontSize: 16,
      textTransform: "capitalize",
    },
    divider: {
      margin: "48px 0px 16px",
      backgroundColor: "white",
    },
    copywriting: {
      textAlign: "center",
    },
    socialMediaButton: {
      color: "white",
      padding: 0,
    },
  })
);

export default useStyles;
